<template>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group class="mb-0">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
          @click="showModalAddOffer()"
        >
          ADD Offer
        </b-button>
      </b-form-group>
    </b-col>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(icon)="data">
          <b-img
            left
            class="mb-1 mb-sm-0"
            height="40"
            :src="data.value"
            alt="Left image')"
          />
        </template>
        <template #cell(cat)="data">
          <span
            style="color: blue"
            @click="showModelListCategories(data)"
          >({{ data.value.length }})</span>Category
        </template>

        <!-- <template #cell(icon_categ)="data">
          <img
            width="20px"
            height="20px"
            v-for="(item, index) in getImagesUrlList(data.value)"
            :src="item"
            :key="item + index"
          />
        </template> -->

        <template #cell(action)="data">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="showModalUpdateOffer(data)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showComfirmDeleteOffer(data)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>
    </b-col>

    <!-- modal list categories -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-offer-category"
      title="User Cards"
      ok-only
      ok-title="ok"
    >
      <div
        v-for="item in currentCategories"
        :key="item.id"
      >
        <b-row class="p-1">
          <img
            width="60px"
            height="50px"
            :src="getImagesUrl(item.name)"
          >
          <span class="pt-1 pl-1">{{ item.previewName }}</span>
        </b-row>
        <hr class=".divider-Dark">
      </div>
    </b-modal>
    <!-- End modal list categories -->

    <!-- modal add offer -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-add-offer"
      title="Add Rewards"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOkAddOffer"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="idState"
          label="ID"
          label-for="id-input"
          invalid-feedback="Id is required"
        >
          <b-form-input
            id="id-input"
            v-model="id"
            :state="idState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="nameState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="offerValueState"
          label="Value"
          label-for="offerValue-input"
          invalid-feedback="Offer Value is required"
        >
          <b-form-input
            id="offerValue-input"
            v-model="offerValue"
            :state="offerValueState"
            required
            type="number"
          />
        </b-form-group>

        <b-form-group
          :state="percentageState"
          label="Discovered at"
          label-for="discovered-input"
          invalid-feedback="Discovered at is required"
        >
          <flat-pickr
            v-model="discovery"
            class="form-control"
          />
        </b-form-group>

        <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Categories</label>
          <v-select
            v-model="selectedCat"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="categories"
            label="name"
          >
            <template #option="item">
              <span class="mr-1 mb-sm-0"> {{ item.previewName }}</span>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Card Name</label>
          <v-select
            v-model="selected1"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="cards"
            label="customName"
          >
            <template #option="item">
              <b-img
                left
                class="mr-1 mb-sm-0"
                height="18"
                :src="item.icon"
                alt="Left image')"
              />
              <span> {{ item.customName }} </span>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Category Icon</label>
          <v-select
            v-model="selectedIcon"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="categories"
            label="name"
          >
            <template #option="item">
              <b-img
                left
                class="mr-1 mb-sm-0"
                height="18"
                :src="getImagesUrl(item.name)"
                alt="Left image')"
              />
              <span> {{ item.previewName }}</span>
            </template>
          </v-select>
        </b-form-group>
        <b-form-group>
          <label class="d-inline-block text-sm-left">Type</label>

          <b-row class="pl-1 pr-1">
            <b-form-select
              id="perPageSelect"
              v-model="type"
              size="sm"
              :options="typeOptions"
            />
          </b-row>
        </b-form-group>
      </form>
    </b-modal>
    <!-- modal add offer -->

    <!-- modal update offer -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-update-offer"
      title="Edit Reward"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOkUpdateOffer"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >

        <b-form-group
          :state="idState"
          label="ID"
          label-for="id-input"
          invalid-feedback="Id is required"
        >
          <b-form-input
            id="id-input"
            v-model="id"
            :state="idState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="nameState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="offerValueState"
          label="Value"
          label-for="offerValue-input"
          invalid-feedback="Offer Value is required"
        >
          <b-form-input
            id="offerValue-input"
            v-model="offerValue"
            :state="offerValueState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="percentageState"
          label="Discovered at"
          label-for="discovered-input"
          invalid-feedback="Discovered at is required"
        >
          <flat-pickr
            v-model="discovery"
            class="form-control"
          />
        </b-form-group>

        <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Categories</label>
          <v-select
            v-model="selectedCat"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="categories"
            label="name"
          >
            <template #option="item">
              <span class="mr-1 mb-sm-0"> {{ item.previewName }}</span>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Card Name</label>
          <v-select
            v-model="selected1"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="cards"
            label="customName"
          >
            <template #option="item">
              <b-img
                left
                class="mr-1 mb-sm-0"
                height="18"
                :src="item.icon"
                alt="Left image')"
              />
              <span> {{ item.customName }}</span>
            </template>
          </v-select>
        </b-form-group>

        <!-- <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Category Icon</label>
          <v-select
            v-model="selectedIcon"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="categories"
            label="name"
          >
            <template #option="item">
              <b-img
                left
                class="mr-1 mb-sm-0"
                height="18"
                :src="getImagesUrl(item.name)"
                alt="Left image')"
              />
              <span> {{ item.previewName }}</span>
            </template>
          </v-select>
        </b-form-group> -->
        <b-form-group>
          <label class="d-inline-block text-sm-left">Type</label>

          <b-row class="pl-1 pr-1">
            <b-form-select
              id="perPageSelect"
              v-model="type"
              size="sm"
              :options="typeOptions"
            />
          </b-row>
        </b-form-group>
      </form>
    </b-modal>
    <!-- modal update offer -->

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import Parse from 'parse'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: '',
      offerValue: '',
      name: '',
      discovery: '',
      percentageState: null,
      idState: null,
      nameState: null,
      offerValueState: null,
      selected1: [],
      selectedCat: [],
      selectedIcon: [],
      cards: [],
      currentOffer: '',
      type: 'CashBack',
      typeOptions: ['CashBack', 'Points', 'Miles'],
      categories: [],
      currentCategories: [],
      perPage: 50,
      pageOptions: [50, 75, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'cardId', label: 'Id', sortable: true },
        { key: 'businessName', label: 'Business Name' },
        { key: 'offerValue', label: 'Value', sortable: true },
        { key: 'dateDiscovered', label: 'Discovered AT', sortable: true },
        { key: 'offerType', label: 'Type', sortable: true },
        { key: 'cat', label: 'Category', sortable: true },
        { key: 'cardName', label: 'Card Name', sortable: true },
        // { key: 'description', label: 'Description', sortable: true },
        // { key: "icon_categ", label: "Category Icons", sortable: true },
        'action',
      ],
      items: [],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.getCategories()
    this.getCards()
    this.getRows()
  },
  methods: {
    resetModal() {
      this.id = ''
      this.name = ''
      this.offerValue = ''
      this.discovery = ''
      this.selected1 = []
      this.selectedCat = []
      this.selectedIcon = []
    },

    handleOkAddOffer(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitAddOffer()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names

      this.updateOffer()

      // Hide the modal manually
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      this.emailState = valid
      this.passwordState = valid
      return valid
    },
    handleSubmitAddOffer() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names

      this.addNewOffer()
      // Hide the modal manually
    },
    updateOffer() {


      const Offers = Parse.Object.extend('Offers')
      const query = new Parse.Query(Offers)
      query.equalTo('objectId', this.currentOffer.item.objectId)
      query
        .first()
        .then(offer => {
          offer.set('cardID', parseInt(this.id))
          offer.set('businessName', this.name)
          offer.set('offerValue', parseInt(this.offerValue))
          offer.set('dateDiscovered', this.discovery)
          offer.set('offerType', this.type)
          offer.set(
            'cat',
            this.selectedCat.map(item => item.name),
          )
          offer.set('cardName', this.selected1.customName)
          // offer.set(
          //   "icon_categ",
          //   this.selectedIcon.map((item) => {
          //     return item.description;
          //   })
          // );
          offer
            .save()
            .then(result => {
              this.$refs['my-modal-update-offer'].hide()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Card added Successfully',
                  icon: 'BellIcon',
                  text: this.name,
                  variant: 'success',
                },
              })
              this.getRows()
            })
            .catch(e => {

              console.error(e)
            })
        })
        .catch(e => {
          console.error(e)
        })
    },
    addNewOffer() {

      const that = this

      const Offers = Parse.Object.extend('Offers')
      const offer = new Offers()
      offer.set('cardID', parseInt(this.id))
      offer.set('businessName', this.name)
      offer.set('offerValue', parseInt(this.offerValue))
      offer.set('dateDiscovered', this.discovery)
      offer.set('offerType', this.type)
      offer.set(
        'cat',
        this.selectedCat.map(item => item.name),
      )
      offer.set('cardName', this.selected1.customName)
      offer.set(
        'icon_categ',
        this.selectedIcon.map(item => item.name),
      )
      offer
        .save()
        .then(result => {

          this.$refs['my-modal-add-offer'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Card added Successfully',
              icon: 'BellIcon',
              text: this.name,
              variant: 'success',
            },
          })
          this.getRows()
        })
        .catch(e => {

          console.error(e)
        })
    },
    showModalAddOffer() {
      this.$refs['my-modal-add-offer'].show()
    },
    showModalUpdateOffer(offer) {
      this.id = offer.item.cardId
      this.name = offer.item.businessName
      this.offerValue = offer.item.offerValue
      this.discovery = offer.item.dateDiscovered
      offer.item.cat.map(item => {
        this.categories.forEach(mycat => {
          if (item == mycat.name) {
            this.selectedCat.push(mycat)
          }
        })
      })
      this.cards.forEach(card => {
        if (offer.item.cardName == card.customName) {
          this.selected1.push(card)
        }
      })

      // offer.item.icon_categ.map((item) => {
      //   this.categories.forEach((mycat) => {
      //     if (item == mycat.name) {
      //       this.selectedIcon.push(mycat);
      //     }
      //   });
      // });

      this.currentOffer = offer
      this.$refs['my-modal-update-offer'].show()
    },
    showModelListCategories(offer) {
      this.currentCategories = this.categories.filter(item => {
        if (offer.value.includes(item.name)) {
          return item
        }
      })
      if (offer.value.length > 0) {
        this.$refs['my-modal-offer-category'].show()
      }
    },
    handleOkUpdateOffer(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    showComfirmDeleteOffer(offer) {

      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete offer  ${
            offer.item.businessName
          } .`,
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            const that = this
            const User = Parse.Object.extend('Offers')
            const query = new Parse.Query(User)
            query.equalTo('objectId', offer.item.objectId)
            query
              .find()
              .then(results => {

                results.forEach(object => {
                  object
                    .destroy()
                    .then(myObject => {

                      // The object was deleted from the Parse Cloud.
                      that.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'User Deleted Successfully',
                          icon: 'BellIcon',
                          text: offer.item.username,
                          variant: 'success',
                        },
                      })
                      that.getRows()
                    })
                    .catch(error => {

                      console.log(error)
                    })
                })
              })
              .catch(e => {

                console.log(error)
              })
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getRows() {
      // get users from server

      const that = this
      that.items = []
      const Offers = Parse.Object.extend('Offers')

      const query = new Parse.Query(Offers)
      if (this.$route.params.id != null) {
        query.equalTo('cardID', parseInt(this.$route.params.id))

      }
      query
        .descending('createdAt')
        .limit(10000)
        .find()
        .then(results => {

          // alert("Successfully retrieved " + results.length + " scores.");
          that.totalRows = results.length
          results.forEach(element => {
            console.log(element)
            if (element.dn != 'SPAM ') {
              that.items.push({
                objectId: element.id,
                cardId: element.get('cardID'),
                businessName: element.get('businessName'),
                offerValue: element.get('offerValue'),
                dateDiscovered: element.get('dateDiscovered'),
                offerType: element.get('offerType'),
                cat: element.get('cat') ?? [],
                cardName: element.get('cardName'),
                // description:element.get("description"),
                category: element.get('category'),
                // icon_categ: element.get("icon_categ"),

                // mark: element.marked == 1 ? true : false,
              })
            }
          })
        })
        .catch(e => {
          alert(e)
        })
    },

    getImagesUrl(list) {
      return require(`@/assets/images/icons/icons/${list}.png`)
    },

    getImagesUrlList(list) {
      return list.map(item => require(`@/assets/images/icons/icons/${item}.png`))
    },
    getCategories() {
      // get users from server
      const that = this
      that.cards = []
      const Categories = Parse.Object.extend('categories')
      const query = new Parse.Query(Categories)
      query
        .descending('createdAt')
        .limit(10000)
        .find()
        .then(results => {
          results.forEach(element => {
            if (element.dn != 'SPAM ') {
              that.categories.push({
                name: element.get('name'),
                previewName: element.get('preview_name'),
              })
            }
          })
        })
        .catch(e => {
          console.log(e)
        })
    },
    getCards() {
      // get users from server
      const that = this
      that.cards = []
      const Cards = Parse.Object.extend('AvailableCards')
      const query = new Parse.Query(Cards)
      query
        .descending('createdAt')
        .limit(10000)
        .find()
        .then(results => {
          results.forEach(element => {
            console.log(element)
            if (element.dn != 'SPAM ') {
              that.cards.push({
                cardId: element.get('cardId'),
                name: element.get('name'),
                icon: element.get('images')._url,
                cardType: element.get('cardTypeName'),
                description: element.get('description'),
                reward: element.get('card_type'),
                annual_fee: element.get('annual_fee'),
                intro_offer: element.get('intro_offer'),
                regular_apr: element.get('regular_apr'),
                url: element.get('url'),
                customName: `${element.get('name')} ${element.get('cardTypeName')}`,
              })
            }
          })
        })
        .catch(e => {
          console.log(e)
        })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
